<template>
  <div>
    <div class="my-5 pt-sm-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <div class="mb-5">
                <a href="/">
                  <img src="@/assets/images/logo.svg" alt="logo" height="50" />
                </a>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-sm-10 offset-sm-1 col-xs-12">
                    <div class="card">
                      <div class="card-body">asd</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";

export default {
  name: "rsvp",
  created() {
    HTTP.get("public/invitations/" + this.$route.params.id).then((result) => {
      console.log(result);
    });
  },
};
</script>